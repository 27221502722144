import React, { useState, useEffect, useRef } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./AppContext";
import "./BarcodeScannerAndSearch.css";
const qrcodeRegionId = "html5qr-code-full-region";

const PiracyBarcode = () => {
  const { lastScannedBarcode, setLastScannedBarcode } = useAppContext();
  const [isCameraBlurred, setIsCameraBlurred] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isInputMode, setIsInputMode] = useState(false);
  const scannerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    checkAndStartScanner();
    return () => {
      if (scannerRef.current) {
        scannerRef.current.stop().catch((error) => {
          console.error("Failed to stop html5Qrcode scanner.", error);
        });
      }
    };
  }, []);
  const checkAndStartScanner = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "camera",
      });
      if (permissionStatus.state === "granted") {
        startScanner();
      } else if (permissionStatus.state === "prompt") {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          startScanner();
          stream.getTracks().forEach((track) => track.stop());
        } catch (error) {
          console.error("Camera access denied:", error);
        }
      } else {
        console.error(
          "Camera permission is denied. Please allow camera access to scan."
        );
      }
    } catch (error) {
      console.error("Error checking camera permission:", error);
    }
  };
  const startScanner = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      let rearCameraId = videoDevices.find(
        (device) =>
          device.label.toLowerCase().includes("back") &&
          device.label.includes("2 0")
      )?.deviceId;
      if (!rearCameraId) {
        rearCameraId = videoDevices.find((device) =>
          device.label.toLowerCase().includes("back")
        )?.deviceId;
      }
      const config = {
        fps: 10,
        qrbox: { width: 300, height: 100 },
        aspectRatio: 1.7777778,
      };
      scannerRef.current = new Html5Qrcode(qrcodeRegionId);
      await scannerRef.current.start(
        rearCameraId || videoDevices[0]?.deviceId,
        config,
        onScanSuccess,
        onScanError
      );
    } catch (error) {
      console.error("Camera initialization error:", error);
    }
  };
  const onScanSuccess = (decodedText, decodedResult) => {
    console.log("Scanned result:", decodedText);
    setLastScannedBarcode(decodedText);
    handleSearch(decodedText);
    setIsCameraBlurred(true);
  };
  const onScanError = (error) => {
    console.error(`Scan Error: ${error}`);
  };
  const handleSearch = async (barcode) => {
    if (barcode.trim() === "") {
      console.log("Barcode is empty. Skipping search.");
      return;
    }
  };
  useEffect(() => {
    if (lastScannedBarcode) {
      const timer = setTimeout(() => {
        navigate("/Declaration", {
          state: { lastScannedBarcode: lastScannedBarcode },
        });
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [lastScannedBarcode]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputValue.trim() !== "") {
      navigate("/Declaration", {
        state: { lastScannedBarcode: inputValue },
      });
    } else {
      console.log("Barcode is empty. Skipping search.");
    }
  };

  const handleSwitchMode = () => {
    if (scannerRef.current && !isInputMode) {
      try {
        if (scannerRef.current.isScanning()) {
          scannerRef.current.stop().catch((error) => {
            console.error("Failed to stop html5Qrcode scanner.", error);
          });
        }
      } catch (error) {
        console.error("Error occurred while stopping the scanner:", error);
      }

      const qrReaderElement = document.getElementById(qrcodeRegionId);
      if (qrReaderElement) {
        qrReaderElement.style.display = "none";
      }
    }

    setIsInputMode((prevMode) => !prevMode);
  };

  return (
    <div className={`app-container ${isCameraBlurred ? "blurred" : ""}`}>
      <div id={qrcodeRegionId} className="qr-reader"></div>
      {lastScannedBarcode && (
        <p className="scanned-barcode">
          {lastScannedBarcode} <p>Redirecting...</p>
        </p>
      )}

      {!isInputMode && (
        <div>
          <button className="switch-mode-button" onClick={handleSwitchMode}>
            Switch to Input Mode
          </button>
        </div>
      )}

      {isInputMode && (
        <div className={`input-container ${isInputMode ? "" : "hidden"}`}>
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Enter barcode"
          />
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default PiracyBarcode;
