// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useLocation } from "react-router-dom";
// import "./Declaration.css";

// function Declaration() {
//   const [source, setSource] = useState("");
//   const [matData, setMatData] = useState([]);
//   const [registrationMessage, setRegistrationMessage] = useState(null);
//   const [forceRender, setForceRender] = useState(false); // Add a state variable for force re-render

//   const location = useLocation();

//   const { lastScannedBarcode } = location.state || {};

//   useEffect(() => {
//     axios
//       .get("https://nodei.ssccglpinnacle.com/getApproveDPO")
//       .then((response) => {
//         const latest30Data = response.data.map(
//           ({ shipmentid, distributorName }) => ({
//             shipmentid,
//             distributorName,
//           })
//         );
//         setMatData(latest30Data);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   useEffect(() => {
//     if (lastScannedBarcode) {
//       handleSubmit();
//     }
//   }, [lastScannedBarcode, forceRender]); // Add forceRender to dependencies array

//   const handleSubmit = async () => {
//     try {
//       if (lastScannedBarcode.includes("-")) {
//         const secondResponse = await axios.post(
//           "https://nodei.ssccglpinnacle.com/searchBarr",
//           { searchValue: lastScannedBarcode }
//         );
//         const data2 = await secondResponse.data;
//         if (
//           data2.result === "Distributor" ||
//           data2.result === "Pinnacle B2C" ||
//           data2.result === "Pinnacle Warehouse" ||
//           data2.result === "Flipkart B2C" ||
//           data2.result === "Amazon B2C"
//         ) {
//           let newSource;
//           if (data2.result === "Distributor") {
//             const shipmentid = data2.OrderNum;
//             const distributor = matData.find(
//               (item) => item.shipmentid === shipmentid
//             );
//             if (distributor) {
//               newSource = distributor.distributorName;
//             }
//           } else {
//             newSource = data2.result;
//           }
//           setSource(newSource);
//           setForceRender(!forceRender); // Toggle forceRender to trigger re-render
//         } else {
//           setRegistrationMessage("This Book not matched with our Database");
//         }
//       } else {
//         // Your existing code for handling non-"Distributor" case
//         const response = await axios.get(
//           `https://nodei.ssccglpinnacle.com/getKey/${lastScannedBarcode}`
//         );
//         const key = response.data.key;

//         console.log(key);

//         if (!key) {
//           setRegistrationMessage("Key not found for given value");
//           return; // Exiting the function if key is not found
//         }

//         // Execute POST request for 'Not Found'
//         const secondResponse = await axios.post(
//           "https://nodei.ssccglpinnacle.com/searchBarr",
//           { searchValue: key }
//         );
//         const data2 = await secondResponse.data;
//         console.log(data2);

//         if (
//           data2.result === "Distributor" ||
//           data2.result === "Pinnacle B2C" ||
//           data2.result === "Pinnacle Warehouse" ||
//           data2.result === "Flipkart B2C" ||
//           data2.result === "Amazon B2C"
//         ) {
//           let newSource;
//           if (data2.result === "Distributor") {
//             const shipmentid = data2.OrderNum;
//             const distributor = matData.find(
//               (item) => item.shipmentid === shipmentid
//             );
//             if (distributor) {
//               newSource = distributor.distributorName;
//             }
//           } else {
//             newSource = data2.result;
//           }
//           setSource(newSource);
//           setForceRender(!forceRender);
//         } else {
//           setRegistrationMessage("This Book not matched with our Database");
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   return (
//     <div className="Maindiv">
//       <br />
//       <br />
//       <br />
//       <h5>Piracy Check</h5>
//       <h5>Barcode Value:{lastScannedBarcode}</h5>
//       {registrationMessage && <h3>{registrationMessage}</h3>}
//       <div>
//         {source && (
//           <div>
//             <h2>Book Source: {source}</h2>
//             <p style={{ color: "green " }}>Verified Successfully</p>
//           </div>
//         )}
//       </div>

//       <a href="https://distributor.ssccglpinnacle.com/">
//         <p>Go Back</p>
//       </a>
//     </div>
//   );
// }

// export default Declaration;


import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

function Declaration() {
  const [source, setSource] = useState("");
  const [registrationMessage, setRegistrationMessage] = useState(null);
  const [barcodeCount, setBarcodeCount] = useState(null);
  const location = useLocation();
  const { lastScannedBarcode } = location.state || {};

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSubmit();
    }, 500);
    // Clear the timeout to avoid memory leaks
    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures this effect runs only once after mounting

  console.log(lastScannedBarcode);

  const postBarcodeToMongoDB = async () => {
    try {
      await axios.post("https://nodei.ssccglpinnacle.com/barcodeadd", {
        barcode: lastScannedBarcode,
      });
      console.log("Barcode posted to MongoDB successfully.");
    } catch (error) {
      console.error("Error posting barcode to MongoDB:", error);
    }
  };

  const getCountFromAPI = async (barcode) => {
    try {
      const response = await axios.post(
        "https://nodei.ssccglpinnacle.com/count",
        {
          barcode,
        }
      );
      setBarcodeCount(response.data.count);
    } catch (error) {
      console.error("Error getting barcode count:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      if (lastScannedBarcode.includes("-")) {
        const secondResponse = await axios.post(
          "https://nodei.ssccglpinnacle.com/searchBarr1",
          { searchValue: lastScannedBarcode }
        );

        const data2 = await secondResponse.data;

        console.log(data2.result);
        if (data2.result !== "Not Verified") {
          setSource(data2.result);
          postBarcodeToMongoDB();
          getCountFromAPI(lastScannedBarcode);
        } else {
          setRegistrationMessage("This Book not matched with our Database");
        }
      } else {
        const response = await axios.get(
          `https://nodei.ssccglpinnacle.com/getKey/${lastScannedBarcode}`
        );
        const key = response.data.key;
        console.log(key);

        if (!key) {
          setRegistrationMessage("Key not found for given value");
          return; // Exiting the function if key is not found
        }
        // Execute POST request for 'Not Found'
        const secondResponse = await axios.post(
          "https://nodei.ssccglpinnacle.com/searchBarr1",
          { searchValue: key }
        );
        const data2 = await secondResponse.data;

        if (data2.result !== "Not Verified") {
          setSource(data2.result);
          postBarcodeToMongoDB();
          getCountFromAPI(lastScannedBarcode);
        } else {
          setRegistrationMessage("This Book not matched with our Database");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="Maindiv">
      <h5>Piracy Check</h5>
      {registrationMessage && <h3>{registrationMessage}</h3>}
      {source && <h2>Book Source : {source}</h2>}
      {barcodeCount !== null && (
        <p id="pira">{`Barcode verified successfully ${barcodeCount} time(s).`}</p>
      )}
      {barcodeCount > 3 && <h4 className="doubtful"> so it is Doubtful</h4>}
    </div>
  );
}

export default Declaration;
